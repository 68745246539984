import axios from 'axios';


const API = {
    // Email must be base64 encoded
    checkEmail: function (url: string, email: string) {
        return axios.post(`${url}/api/email/check`, { email: email });
    },
    createPassword: function (url: string, email: string) {
        return axios.post(`${url}/api/password/create`, { email })
    },
    confirmPasswordResetCode: function (url: string, email: string, code: string) {
        return axios.post(`${url}/api/password/confirm`, { code, email })
    },
    updatePassword: function (url: string, email: string, key: string) {
        return axios.post(`${url}/api/password/update`, { email, key })
    },
    getEnvironmentSettings: function () {
        return axios.get("/api/environment");
    }
}

export default API;